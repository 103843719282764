import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { refresh } from "@mittwald/react-use-promise";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { Signup } from "../signup/Signup";
import Contributor from "./Contributor";
import Extension, { MarketplaceContext } from "./Extension";

export type ExtensionInstanceApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Marketplace_ExtensionInstance;

export type AddToContextValues =
  MittwaldApi.Paths.V2_Extension_Instances.Post.Parameters.RequestBody;

interface ConsentToScopeValues {
  consentedScopes: string[];
}

class ExtensionInstance {
  public readonly data: ExtensionInstanceApiData;
  public readonly id: string;
  public readonly extensionId: string;
  public readonly isDisabled: boolean;
  public readonly hasProjectContext: boolean;
  public readonly hasCustomerContext: boolean;
  public readonly context: MarketplaceContext;

  public constructor(data: ExtensionInstanceApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.extensionId = data.extensionId;
    this.isDisabled = data.disabled;
    this.hasProjectContext = data.aggregateReference.domain === "project";
    this.hasCustomerContext = data.aggregateReference.domain === "customer";
    this.context = this.data.aggregateReference.aggregate as MarketplaceContext;
  }

  public static async addToContext(
    values: AddToContextValues,
  ): Promise<string> {
    const response = await mittwaldApi.extensionCreateExtensionInstance.request(
      {
        requestBody: { ...values },
      },
    );

    assertStatus(response, 201);

    refresh({
      tag: "extension-instance-list",
    });

    return response.content.id;
  }

  public static async addToCustomerContext(
    values: Omit<AddToContextValues, "context">,
  ): Promise<string> {
    return await ExtensionInstance.addToContext({
      ...values,
      context: "customer",
    });
  }

  public static async addToProjectContext(
    values: Omit<AddToContextValues, "context">,
  ): Promise<string> {
    return await ExtensionInstance.addToContext({
      ...values,
      context: "project",
    });
  }

  public static useLoadById(extensionInstanceId: string): ExtensionInstance {
    const data = mittwaldApi.extensionGetExtensionInstance
      .getResource({ path: { extensionInstanceId } })
      .useWatchData();

    return new ExtensionInstance(data);
  }

  public static useTryLoadById(
    extensionInstanceId: string,
  ): ExtensionInstance | undefined {
    const data = mittwaldApi.extensionGetExtensionInstance
      .getResource(
        extensionInstanceId ? { path: { extensionInstanceId } } : null,
      )
      .useWatchData({ optional: true });

    return data ? new ExtensionInstance(data) : undefined;
  }

  public static async tryLoadById(
    extensionInstanceId: string,
  ): Promise<ExtensionInstance | undefined> {
    const data = await mittwaldApi.extensionGetExtensionInstance.request({
      path: {
        extensionInstanceId,
      },
    });

    if (data.status !== 200) {
      return undefined;
    }

    return new ExtensionInstance(data.content);
  }

  public async consentToScopes(values: ConsentToScopeValues): Promise<void> {
    const response =
      await mittwaldApi.extensionConsentToExtensionScopes.request({
        path: { extensionInstanceId: this.id },
        requestBody: { ...values },
      });

    assertStatus(response, 204);
  }

  public async enable(): Promise<void> {
    const response = await mittwaldApi.extensionEnableExtensionInstance.request(
      { path: { extensionInstanceId: this.id } },
    );

    assertStatus(response, 204);
  }

  public async disable(): Promise<void> {
    const response =
      await mittwaldApi.extensionDisableExtensionInstance.request({
        path: { extensionInstanceId: this.id },
      });

    assertStatus(response, 204);
  }

  public async delete(): Promise<void> {
    const response = await mittwaldApi.extensionDeleteExtensionInstance.request(
      {
        path: { extensionInstanceId: this.id },
      },
    );

    refresh({
      tag: "extension-instance-list",
    });

    assertStatus(response, 204);
  }

  public useExtension(): Extension {
    return Extension.useLoadById(this.extensionId);
  }

  public useTryExtension(): Extension | undefined {
    return Extension.useTryLoadById(this.extensionId);
  }

  public async getExtension(): Promise<Extension> {
    return await Extension.loadById(this.extensionId);
  }

  public useContributor(): Contributor {
    return this.useExtension().useContributor();
  }

  public async getContributor(): Promise<Contributor> {
    return (await this.getExtension()).getContributor();
  }

  public static async getExtensionUrl(
    url: string,
    extensionInstanceId: string,
  ): Promise<string> {
    const accessTokenRetrievalKeyData =
      await Signup.getAccessTokenRetrievalKey(extensionInstanceId);

    return url
      .replace(":userId", accessTokenRetrievalKeyData.userId)
      .replace(
        ":accessTokenRetrievalKey",
        accessTokenRetrievalKeyData.accessTokenRetrievalKey,
      );
  }

  public useTryExtensionDetailsAction(
    url: string | undefined,
  ): string | undefined {
    const accessTokenRetrievalKeyData = Signup.useAccessTokenRetrievalKey(
      this.id,
    );

    const extension = this.useExtension();

    if (url === undefined) {
      return undefined;
    }

    return url
      .replace(":userId", accessTokenRetrievalKeyData.userId)
      .replace(":context", this.context)
      .replace(":contextId", this.data.aggregateReference.id)
      .replace(":contributorId", extension.data.contributorId)
      .replace(":extensionId", this.data.extensionId)
      .replace(":extensionInstanceId", this.id)
      .replace(
        ":accessTokenRetrievalKey",
        accessTokenRetrievalKeyData.accessTokenRetrievalKey,
      );
  }
}

export default ExtensionInstance;
